import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Feed from "./components/Feed";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Contact from "./components/Contact";
import ProfessionalExperience from "./components/ProfessionalExperience";
import Education from "./components/Education";
import Projects from "./components/Projects";
import "./App.css"; // Import your global CSS
import jobs from "./data/jobsData";
import education from "./data/educationData";
import projects from "./data/projectsData";



function App() {
  const [isAnimating, setIsAnimating] = useState(false);

  // Function to start animations
  const startAnimation = () => {
    setIsAnimating(true);
    // Set a timeout to reset the animation state after a specified duration
    setTimeout(() => {
      setIsAnimating(false);
    }, 500); // 500ms is the animation duration; adjust as needed
  };

  return (
    <Router>
      <NavBar startAnimation={startAnimation} />
      <div className="content-wrapper">
        <Routes>
          <Route path="/" element={<Feed isAnimating={isAnimating} />} />
          <Route path="/intro" element={<Feed isAnimating={isAnimating} />} />
          <Route path="/home" element={<Home isAnimating={isAnimating} startAnimation={startAnimation} />} />
          <Route
            path="/experience"
            element={<ProfessionalExperience isAnimating={isAnimating} jobs={jobs} />}
          />
                    <Route
            path="/education"
            element={<Education isAnimating={isAnimating} education={education} />}
          />
          <Route
            path="/projects"
            element={<Projects isAnimating={isAnimating} projects={projects} />}
          />
          <Route
            path="/contact"
            element={<Contact isAnimating={isAnimating} />}
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
